import React, { useState, useEffect } from "react";
import axios from "axios";
import { DAILYSPEND_CRUD_API } from "../hooks/APIHooks";
import { IoPencilSharp, IoTrashSharp } from "react-icons/io5";
import { useTable, useSortBy } from "react-table";
import { GiPowerLightning } from "react-icons/gi";
import {
  FaCarSide,
  FaPeopleCarry,
  FaHandHoldingWater,
  FaMoneyBillAlt,
} from "react-icons/fa";

const DailySpendPage = () => {
  const [spends, setSpends] = useState([]);
  const [dailySpend, setDailySpend] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [editId, setEditId] = useState(null);
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("Other");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1); // Add one day
    return today.toISOString().split("T")[0];
  });

  const [totalSpend, setTotalSpend] = useState(0); // State to hold total spend

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("");

  const months = [
    { value: "", label: "All" },
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const getStartDate = () => {
    if (!selectedYear) return "";
    if (selectedMonth === "") return ""; // "All" month, so no specific date range
    return new Date(selectedYear, selectedMonth - 1, 1)
      .toISOString()
      .split("T")[0];
  };

  const getEndDate = () => {
    if (!selectedYear) return "";
    if (selectedMonth === "") return ""; // "All" month, so no specific date range
    const endMonth = selectedMonth === 12 ? 0 : selectedMonth; // Wrap around if December
    const endYear = selectedMonth === 12 ? selectedYear + 1 : selectedYear;
    return new Date(endYear, endMonth, 1).toISOString().split("T")[0];
  };

  useEffect(() => {
    setStartDate(getStartDate());
    setEndDate(getEndDate());
    fetchSpends();
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    fetchSpends();
  }, [selectedYear, selectedMonth]);

  // Update fetchSpends to handle "All" month
  const fetchSpends = async () => {
    try {
      let params = {};
      if (selectedYear) {
        if (selectedMonth) {
          params = { startDate: getStartDate(), endDate: getEndDate() };
        } else {
          // If month is "All", fetch for the whole year
          params = {
            startDate: `${selectedYear}-01-01`,
            endDate: `${selectedYear}-12-31`,
          };
        }
      }
      const response = await axios.get(DAILYSPEND_CRUD_API, { params });
      setSpends(response.data);
      calculateTotalSpend(response.data); // Update total spend calculation
    } catch (error) {
      console.error("Error fetching spends:", error);
    }
  };

  const calculateTotalSpend = (spends) => {
    const total = spends.reduce(
      (acc, spend) => acc + parseFloat(spend.daily_spend || 0),
      0
    );
    setTotalSpend(total);
  };

  const handleAddOrUpdate = async (event) => {
    event.preventDefault();
    try {
      const data = {
        daily_spend: dailySpend,
        description,
        created_at: `${date} ${time}`, // Combine date and time
        category,
      };
      if (editId) {
        // Update existing spend
        await axios.put(DAILYSPEND_CRUD_API, { ...data, id: editId });
        setMessage("Spend updated successfully");
      } else {
        // Add new spend
        await axios.post(DAILYSPEND_CRUD_API, data);
        setMessage("New spend added successfully");
      }
      fetchSpends();
      resetForm();
    } catch (error) {
      console.error("Error saving spend:", error);
      setMessage("Error saving spend");
    }
  };

  const handleEdit = (spend) => {
    setDailySpend(spend.daily_spend);
    setDescription(spend.description);
    setDate(spend.created_at.split(" ")[0]); // Extract date
    setTime(spend.created_at.split(" ")[1]); // Extract time
    setEditId(spend.id);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this spend?")) {
      try {
        await axios.delete(`${DAILYSPEND_CRUD_API}?id=${id}`);
        setMessage("Spend deleted successfully");
        fetchSpends();
      } catch (error) {
        console.error("Error deleting spend:", error);
        setMessage("Error deleting spend");
      }
    }
  };

  const resetForm = () => {
    setDailySpend("");
    setDescription("");
    setDate("");
    setTime("");
    setEditId(null);
    setMessage("");
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format as DD-MM-YYYY
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date & Time",
        accessor: "created_at",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Daily Spend",
        accessor: "daily_spend",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        id: "actions",
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              onClick={() => handleEdit(row.original)}
              className="bg-violet-500 text-white p-1 rounded-md hover:bg-violet-600 transition duration-200 flex items-center justify-center"
            >
              <IoPencilSharp />
            </button>
            <button
              onClick={() => handleDelete(row.original.id)}
              className="bg-red-500 text-white p-1 rounded-md hover:bg-red-600 transition duration-200 flex items-center justify-center"
            >
              <IoTrashSharp />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const getCategoryIcon = (category) => {
    switch (category) {
      case "Power":
        return <GiPowerLightning className="text-yellow-500 text-xl" />;
      case "Travel":
        return <FaCarSide className="text-blue-500 text-xl" />;
      case "Commission":
        return <FaPeopleCarry className="text-green-500 text-xl" />;
      case "Water Bill":
        return <FaHandHoldingWater className="text-teal-500 text-xl" />;
      default:
        return <FaMoneyBillAlt className="text-gray-500 text-xl" />;
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: spends,
      },
      useSortBy
    );

  return (
    <div className="p-4 bg-gray-50 mb-6">
      <h1 className="text-3xl font-bold text-gray-800">Daily Expenditures</h1>

      {message && (
        <div className="mb-6 p-4 text-white bg-violet-600">{message}</div>
      )}

      <form onSubmit={handleAddOrUpdate} className="bg-white p-4">
        <h2 className="text-xl font-semibold mb-4">
          {editId ? "Update Spend" : "Add New Spend"}
        </h2>
        <div className="grid grid-cols-1 gap-2 mb-6">
          <div>
            <input
              type="number"
              placeholder="Daily Spend (INR)"
              id="dailySpend"
              value={dailySpend}
              onChange={(e) => setDailySpend(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
              step="0.01"
              required
            />
          </div>
          <div>
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
            >
              <option value="Power">Power</option>
              <option value="Travel">Travel</option>
              <option value="Commission">Commission</option>
              <option value="Water Bill">Water Bill</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div>
            <textarea
              id="description"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700"
              >
                Date
              </label>
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
                required
              />
            </div>
            <div>
              <label
                htmlFor="time"
                className="block text-sm font-medium text-gray-700"
              >
                Time
              </label>
              <input
                type="time"
                id="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
                required
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-violet-600 text-white py-2 px-4 rounded-md hover:bg-violet-700 transition duration-200"
        >
          {editId ? "Update Spend" : "Add Spend"}
        </button>
      </form>

      <div className="mb-6 space-x-2 grid grid-cols-2">
        <div>
          <label
            htmlFor="month"
            className="block text-sm font-medium text-gray-700"
          >
            Month
          </label>
          <select
            id="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
          >
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label
            htmlFor="year"
            className="block text-sm font-medium text-gray-700"
          >
            Year
          </label>
          <input
            type="number"
            id="year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
          />
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold">Expenditure List</h2>
        <p className="text-sm">
          Selected Duration will only be calculated and shown below the total
          spend.
        </p>
        <div className="my-4 text-center bg-red-500 text-white">
          <span className="font-bold">Total Spend:</span> ₹
          {totalSpend.toFixed(2)}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {spends.map((spend) => (
            <div
              key={spend.id}
              className="p-4 bg-white shadow rounded-lg border"
            >
              <div className="flex items-center space-x-2">
                {getCategoryIcon(spend.category)}
                <h3 className="text-lg font-semibold">{spend.category}</h3>
              </div>
              <p className="text-sm text-gray-600">
                {new Date(spend.created_at).toLocaleString()}
              </p>
              <p className="mt-2 text-gray-800 font-semibold">
                ₹{spend.daily_spend}
              </p>
              <p className="text-gray-600">{spend.description}</p>
              <div className="mt-4 space-x-2 flex justify-between">
                <button
                  onClick={() => handleEdit(spend)}
                  className="bg-violet-600 text-white px-4 py-2 rounded-md"
                >
                  <IoPencilSharp className="flex mx-auto" />
                </button>
                <button
                  onClick={() => handleDelete(spend.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                >
                  <IoTrashSharp className="flex mx-auto" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DailySpendPage;
