import React from "react";

function Updates() {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg my-5">
      <h2 className="text-2xl font-semibold text-center text-blue-600 mb-6">
        New Update 2.0V
      </h2>
      <div className="space-y-6">
        {/* WhatsApp URL Sharing */}
        <div className="p-4 bg-gray-100 rounded-md shadow-sm">
          <h3 className="text-lg font-semibold text-gray-800">
            1. WhatsApp URL Sharing
          </h3>
          <p className="text-gray-700 mt-2">
            No need to share PDF documents anymore! Simply click on the "Share
            on WhatsApp" button to send the URL directly. Users can easily
            access their invoice by following the link.
          </p>
        </div>

        {/* Menu Card Feature */}
        <div className="p-4 bg-gray-100 rounded-md shadow-sm">
          <h3 className="text-lg font-semibold text-gray-800">
            2. Menu Card Feature
          </h3>
          <p className="text-gray-700 mt-2">
            Any products you add to the Products page will automatically appear
            on your Menu. Visit{" "}
            <span className="font-bold text-blue-600">
              www.yourdomain.com/menu
            </span>{" "}
            to view and share the link with users, making it easier to showcase
            your services or products.
          </p>
        </div>

        {/* Admin Access */}
        <div className="p-4 bg-gray-100 rounded-md shadow-sm">
          <h3 className="text-lg font-semibold text-gray-800">
            3. Admin Access
          </h3>
          <p className="text-gray-700 mt-2">
            To configure admin access, please contact Customer Support. Once set
            up, the admin can monitor reports, net profits, and other essential
            metrics.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Updates;
