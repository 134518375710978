import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Updates from "./components/Tools/Updates";
import LoginPage from "./LoginPage";
import Dashboard from "./routes/Dashboard";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import InvoicesPage from "./routes/invoice/InvoicesPage";
import FullInvoice from "./routes/invoice/FullInvoice";
import ProductsPage from "./routes/ProductsPage";
import ClientsPage from "./routes/ClientsPage";
import DailySpendPage from "./routes/DailySpendPage";
import SettingsPage from "./routes/Settings/SettingsPage";
import CompanyDetails from "./routes/Settings/CompanyDetails";
import Analysis from "./routes/Analysis";
import NotFoundPage from "./components/NotFoundPage";
import GrossProfit from "./routes/GrossProfit/grossprofit";
import MenuPage from "./routes/MenuPage";
import PaymentGateway from "./routes/invoice/PaymentGateway";

const App = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  return (
    <>
      {isLoggedIn && <Navbar />} {/* Conditionally render Navbar */}
      <Routes location={location} key={location.pathname}>
        {/* Public routes */}
        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/dashboard" /> : <LoginPage />}
        />
        <Route path="/menu" element={<MenuPage />} /> {/* Public route */}
        {/* Private routes */}
        <Route
          path="/dashboard"
          element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route
          path="/invoices"
          element={isLoggedIn ? <InvoicesPage /> : <Navigate to="/" />}
        />
        <Route path="/invoice/:id" element={<FullInvoice />} />{" "}
        {/* Public route */}
        <Route
          path="/products"
          element={isLoggedIn ? <ProductsPage /> : <Navigate to="/" />}
        />
        <Route
          path="/clients"
          element={isLoggedIn ? <ClientsPage /> : <Navigate to="/" />}
        />
        <Route
          path="/analysis"
          element={isLoggedIn ? <Analysis /> : <Navigate to="/" />}
        />
        <Route
          path="/dailyspend"
          element={isLoggedIn ? <DailySpendPage /> : <Navigate to="/" />}
        />
        <Route
          path="/settings"
          element={isLoggedIn ? <SettingsPage /> : <Navigate to="/" />}
        />
        <Route
          path="/companydetails"
          element={isLoggedIn ? <CompanyDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/grossprofit"
          element={isLoggedIn ? <GrossProfit /> : <Navigate to="/" />}
        />
        {/* 404 Not Found route */}
        <Route path="*" element={<NotFoundPage />} />

        <Route path="/invoice/:id/paymentgateway" element={<PaymentGateway />} /> 
       
        <Route path="/updates" element={<Updates />} /> {/* Public route */}
      </Routes>
      {isLoggedIn && <Footer />} {/* Conditionally render Footer */}
    </>
  );
};

export default App;
