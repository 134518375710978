import React from 'react';

const Error404 = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.errorTitle}>ERROR 404</h1>
      <p style={styles.message}>Page Not Found</p>
      <p style={styles.contact}>
        Contact us at{' '}
        <a href="https://www.cyberspacedigital.in" style={styles.link}>
          www.cyberspacedigital.in
        </a>{' '}
        or call <a href="tel:8143407758" style={styles.link}>8143407758</a>
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
    color: '#333',
    textAlign: 'center',
    padding: '20px',
  },
  errorTitle: {
    fontSize: '48px',
    marginBottom: '20px',
  },
  message: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  contact: {
    fontSize: '18px',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
  },
};

export default Error404;
