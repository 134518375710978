import React, { useState, useEffect } from "react";
import axios from "axios";
import { SETTINGS_CRUD_API } from "../../hooks/APIHooks";
import LogoLoader from "../../components/LogoLoader.jsx"; // Import your Loader component

const Settings = () => {
  const [settings, setSettings] = useState({
    companylogo: "",
    companyName: "",
    companyPhone: "",
    companyPhone2: "",
    companyEmail: "",
    companyAddress: "",
    GSTnumber: "",
    Tax: "",
    invoice_number: "", // New field
    upi_id: "",
    payment_QR: "",
  });
  const [file, setFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [logoFile, setLogoFile] = useState(null);
  const [paymentQRFile, setPaymentQRFile] = useState(null);

  useEffect(() => {
    // Fetch current settings
    axios
      .get(SETTINGS_CRUD_API)
      .then((response) => {
        setSettings(response.data);
        setIsLoading(false); // Stop loading when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        setIsLoading(false); // Stop loading even if there's an error
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleLogoChange = (e) => setLogoFile(e.target.files[0]);
  const handlePaymentQRChange = (e) => setPaymentQRFile(e.target.files[0]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("companyName", settings.companyName);
    formData.append("companyPhone", settings.companyPhone);
    formData.append("companyPhone2", settings.companyPhone2);
    formData.append("companyEmail", settings.companyEmail);
    formData.append("companyAddress", settings.companyAddress);
    formData.append("GSTnumber", settings.GSTnumber);
    formData.append("Tax", settings.Tax);
    formData.append("invoice_number", settings.invoice_number);
    formData.append("upi_id", settings.upi_id); // New field
    if (logoFile) {
      formData.append("companylogo", logoFile);
    }
    if (paymentQRFile) {
      formData.append("payment_QR", paymentQRFile);
    }

    axios
      .post(SETTINGS_CRUD_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert("Settings updated successfully");
        setIsEditing(false);
        // Refresh settings
        setIsLoading(true);
        axios
          .get(SETTINGS_CRUD_API)
          .then((response) => {
            setSettings(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching settings:", error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    // Refresh settings to reset any changes
    setIsLoading(true);
    axios
      .get(SETTINGS_CRUD_API)
      .then((response) => {
        setSettings(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LogoLoader />; // Show loader while data is being fetched
  }

  return (
    <div className="container p-8 max-w-4xl mx-auto mb-5">
      <h1 className="text-2xl font-bold text-center">Settings</h1>
      <p className="text-[12px] mb-4 text-center">
        Please make sure to add all your company details, this details will be
        effected throughout your system.
      </p>
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              value={settings.companyName}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Company Phone
            </label>
            <input
              type="text"
              name="companyPhone"
              value={settings.companyPhone}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Company Phone 2
            </label>
            <input
              type="text"
              name="companyPhone2"
              value={settings.companyPhone2}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Company Email
            </label>
            <input
              type="email"
              name="companyEmail"
              value={settings.companyEmail}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Company Address
            </label>
            <textarea
              name="companyAddress"
              value={settings.companyAddress}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              GST Number
            </label>
            <input
              type="text"
              name="GSTnumber"
              value={settings.GSTnumber}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Tax
            </label>
            <input
              type="text"
              name="Tax"
              value={settings.Tax}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Invoice Number
            </label>
            <input
              type="text"
              name="invoice_number"
              value={settings.invoice_number}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Invoice Number
            </label>
            <input
              type="text"
              name="upi_id"
              value={settings.upi_id}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Company Logo
            </label>
            <input
              type="file"
              name="companylogo"
              onChange={handleLogoChange}
              className="mt-1 block w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Payment QR
            </label>
            <input
              type="file"
              name="payment_QR"
              onChange={handlePaymentQRChange}
              className="mt-1 block w-full"
            />
          </div>

          <div className="flex ">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
            >
              Save Settings
            </button>

            <button
              type="button"
              onClick={handleCancelClick}
              className="bg-gray-500 text-white px-4 py-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div>
          <div className="mb-2">
            <img
              src={`${window.location.origin}/apis/${settings.companylogo}`}
              alt="Company Logo"
              className="w-16 h-auto mx-auto object-cover"
            />
          </div>

          <div className="mb-4 ">
            <h2 className=" font-semibold">
              Company Name:{" "}
              <p className="font-normal">{settings.companyName}</p>
            </h2>
          </div>
          <div className="mb-4">
            <h2 className=" font-semibold">
              Company Phone:{" "}
              <p className="font-normal">{settings.companyPhone}</p>
            </h2>
          </div>
          <div className="mb-4">
            <h2 className=" font-semibold">
              Company Phone 2:{" "}
              <p className="font-normal">{settings.companyPhone2}</p>
            </h2>
          </div>
          <div className="mb-4">
            <h2 className=" font-semibold">
              Company Email:{" "}
              <p className="font-normal">{settings.companyEmail}</p>
            </h2>
          </div>
          <div className="mb-4">
            <h2 className=" font-semibold">
              Company Address:{" "}
              <p className="font-normal">{settings.companyAddress}</p>
            </h2>
          </div>
          <div className="mb-4">
            <h2 className=" font-semibold">
              GST Number: <p className="font-normal">{settings.GSTnumber}</p>
            </h2>
          </div>
          <div className="mb-4">
            <h2 className=" font-semibold">
              Tax: <p className="font-normal">{settings.Tax}</p>
            </h2>
          </div>
          <div className="mb-4">
            <h2 className=" font-semibold">
              Invoice Number:{" "}
              <p className="font-normal">{settings.invoice_number}</p>
            </h2>
          </div>
          <div className="mb-4">
            <h2 className=" font-semibold">
              UPI ID <p className="font-normal">{settings.upi_id}</p>
            </h2>
            <div className="mb-2">
            <h2 className=" font-semibold">
              QR Code
            </h2>
            <img
              src={`${window.location.origin}/apis/${settings.payment_QR}`}
              alt="payment_QR"
              className="w-16 h-auto mx-auto object-cover"
            />
          </div>
          </div>

          <button
            onClick={handleEditClick}
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Edit Settings
          </button>
        </div>
      )}
    </div>
  );
};

export default Settings;
