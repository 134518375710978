import React from 'react';

const Footer = () => {
    return (
        <footer className="text-[10px] bg-black p-4 w-screen text-center text-white fixed bottom-0 ">
            <p>&copy; {new Date().getFullYear()} CyberSpaceDigital. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
